.middlemessage{
  
  padding-top: 50px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
    font-family: 'Gowun Dodum';
    font-size: min(max(10px, 1vw), 20px);
      
}

@media (max-width:750px){
    .middlemessage{ padding-top: 0px;}
}
.skills {
    width: 100%;
    height:fit-content;
    
    background-color: white;
   
    font-family: 'Gowun Dodum';
  font-size: 20px;
  font-weight: 100;

  
}

.skillscontainer{
    width:70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    height: fit-content;
    position: relative;
  
    display: flex;
  
 
  justify-content: space-between;
 
  
}



.skillsleft{
 
    text-align: left;
  
   width: 23.3%;
   
}

.skillslist{
 padding-left: 0px;
 
}

.skillsright{
 

  
    text-align: right;
   
    width: 23.3%;
 
    font-weight: 700;
}

.skillsmiddle{
  text-align: center;
transform: translate(10vw);
  width: 23.3%;
}

.skilllogo{
   width: 50px; 
   
}

li{
   
    list-style-type: none;
  
    
}

.skillsleft li{
  margin-bottom: 50px;
}



li p{
  height: 100px;
  
}

li img{
margin-bottom: 65px;
}


@media(max-width:600px){
  .skillsmiddle{
    transform: translate(10);
  }

  .skilllogo{
    width: 30px; 
    
 }

 li img{
  margin-bottom: 85px;
  }

  .skillsleft{
 font-size: 15px;
 margin-top: 10px;
   
}
  
}
.reviews{
    width: 100vw;
    min-width: 1250px;
    height: 350px;
    background-color: white;
}

.marks{
    position: absolute;
    
    left: 20vw;
    font-size: 200px;
    width:10%;
    min-width: 150px;
    transform: rotate(-180deg) translate(0, -20px);
    
}

.reviewinfo{
    width: 100vw;
    height: 200px;
    position: relative;
    
    text-align: right;
}

.review{
    position: absolute;
    font-family: 'Gowun Dodum';
    font-size: min(max(20px, 3vw), 50px);
  font-weight: 100;
  padding-top: auto;
  padding-bottom: auto;
 margin-top: auto;
 margin-bottom: auto;
 top: 35%;
 bottom: auto;
  z-index: 1;
 width: 50%;
 right: 5vw;
 left: auto;
padding-right: 50px;

  
text-align: right;
  
}

.reviewname{
    text-align: right;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    right: 5vw;
    height: fit-content;
    position: absolute;
    padding-right: 50px;
   
}

.underline{
    width: 400px;
    margin-top: 50px;
    
}

.reviewer{
    font-family: 'Gowun Dodum';
    font-size: min(max(30px, 2vw), 55px);
    font-weight: 700;
margin-right:auto;
margin-left: 20px;
    margin-bottom: -25px;
    
}


@media (max-width: 650px){
    .underline{
        width: 400px;
        margin-top: 70px;
        
    }

    .marks{
        opacity: 0.5;
    }
}
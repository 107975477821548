.experience {
    width: 100%;
    height:fit-content;
   
    background-color: white;
}

.experiencecontainer{
    width:90%;
    margin-left:400px;
    margin-right: auto;
    padding-bottom: 50px;
    height: 150px;
    position: relative;
    text-align: center;
    display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;

}

.experienceleft{
   position: relative;
    text-align: left;
  
  width:20vw;
  
    
}

.experienceright{
    
    text-align: left;
    margin-right:500px;
   width:25vw;


}

.experienceinfo{
  
  transform: translate(100px, 0px);
   text-align: left;
  
}





.dot {
    height: 30px;
    width: 30px;
    background-color: #6BB22B;
    border-radius: 50%;
    display: inline;
    position: absolute;
    transform: translate(0px, 40px);
  }


  .experiencelogo{
    display: inline;
    position: absolute;
    transform: translate(-170px, 40px);
    height: 40px;
    
  }


  

 @media(max-width: 1110px){
  .experiencecontainer{
    width:90%;
    margin-left:auto;
    margin-right: auto;
    height: fit-content;
    padding-bottom: 100px;
}
.experienceleft{
  position: relative;
   text-align: center;
   margin-right:auto;
   margin-left: auto;
 width:80vw;
 
   
}

.experienceright{
   
   text-align: left;
   margin-right:auto;
   margin-left: auto;
  width:50vw;


}

.dates{
  margin-left: 200px;
  margin-right: auto;
}

.experiencelogo{
  display: inline;
  position: absolute;
  transform: translate(-170px, 70px);
  height: 40px;
  
}

.experienceinfo{
  
  transform: translate(20px, 0px);
   text-align: left;
  
}
.dot {

  transform: translate(-30px, 40px);
}
 } 
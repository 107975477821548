.website{
  
 
  padding-top:50px;
  padding-bottom:50px;
  margin-left: auto;
  margin-right: auto;
  
}

.websitecontainer{
  width: 650px;
  height: fit-content;
  
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: auto;
  overflow: hidden;
}



.App {
    font-family: sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    
    
    
  }
  
  .images-container {
    display: flex;
    
    max-width: 1200px;
    overflow: scroll;
    /* Add the following lines for smooth scrolling */
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .image {
    width: 600px;
    height: 400px;
    margin: 30px;
    border-radius: 8px;
  }
  
  .nav-btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
   
    border-radius: 6px;
    height: 50px;
    width: 50px;
    margin: 5px;
  }

  .arrowleft{
    scale: -1 1;
    
  }

  .arrowleft:hover{
    filter: drop-shadow(10px 10px 4px #b1b1b1);
    transition: 0.5s;
  }

  .arrowright{
    margin-left: -80px;
   
  }

  .arrowright:hover{
    filter: drop-shadow(10px 10px 4px #b1b1b1);
    transition: 0.5s;
  }

  .arrowright:active{
    scale: 2;
  }
  .arrowleft:active{
    scale: -2 2;
  }

  .weblink{
    font-family: 'Gowun Dodum';
    font-size: 20px;
    color: #6BB22B;
  }

  .webdescription{
    font-family: 'Gowun Dodum';
    font-size: 20px;
    
  }

.computerdiv{
  height: fit-content;
  overflow: hidden;
 width: 90%;
 margin-left: auto;
 margin-right: auto;
 right: auto;
}

  .computercontainer{
   
    width: 100%;
    margin-left: auto;
 right: auto;
    position: relative;
    
    
  }

  .websitediv{
    width: 100px;
    background-color: #6BB22B;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }

  .computer{
    width:700px;
    margin-left: auto;
    right: auto;
    position: absolute;
    margin: auto;
    left:0;
    right:0;
    top: 50px;
    z-index: -1;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
  }

  .descriptionbox{
    width:70%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.8);
 margin-top: 200px;
 margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

 

  @media (max-width: 900px){
    .computer{
      width:500px;
    }
  
    .image{
      height: 250px !important;
    }
    .websitecontainer{
      width: 500px;}
  

  #phone{
    width: 250px;
  }
}

  
  @media (max-width: 600px){
    .computer{
      width:300px;
    }
    .image{
      height: 150px !important;
      margin: 10px;
    }
    .websitecontainer{
      width: 300px;}

      #phone{
        width: 150px;
      }

      #phone .arrowright{
        scale: 0.5;
      }
      #phone .arrowleft{
        scale: -0.5;
      }
  }
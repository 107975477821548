.showreel{
    width:100%;
    height: fit-content;
    padding-bottom: 50px;
   top:300px;
    text-align: center;
    
   
}

.showreelcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  align-items: center;
    flex-wrap: wrap;
    padding-top: 56.25%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
   
}

.videopaintmark{
    position: absolute;
    top:10vw;
    transform: translate(-10vw, -200px);
}

.descriptiondiv{
    position:relative;
   text-align: center;
   
    height: fit-content;
    width: 90%;
   margin-left: auto;
   margin-right: auto;

   
}

.showreeldescription{
    
    font-family: 'Gowun Dodum';
    font-size: min(max(12px,2vw), 25px);
  font-weight: 100;

 margin-bottom: 50px;
 margin-top: 50px;
  height: fit-content;
  width: 90%;
 z-index: 10;
 margin-left: auto;
 margin-right: auto;
margin-top: -30vw;
color: black !important;
}

.arrow2{
    position: relative;
    width: 18vw;
    bottom: 29vw;
    
    transform: translate(0px, 0px) rotate(50deg);
    scale: -1 1;
    
   
}





.videoframe{
    position: absolute;
    top: 0;
    
   
    width: 50%;
    height: 50%;
    filter: drop-shadow(30px 30px 10px rgba(0, 0, 0, 0.4));
}





 .showreellist li {
    list-style-type: square; 
    color: #020202;
  padding-top: 15px;
  
}


.showreellist{
    list-style-type: none !important;
    text-decoration: none; 
    color: black !important;
}


.showreellist li::marker{
    color: #6BB22B;
}
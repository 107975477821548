.projects{
    background-color:#6BB22B;
    width: 100%;
    color: white;
    height:fit-content;
    padding-top: 0px;
    padding-bottom: 100px;
    
    filter: drop-shadow(0px 10px 4px #b6b6b6);
}

.projectscontainer{
    width:90%;
    margin-left: 10vw;
    margin-right: 10vw;
   
    
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.project{
    margin-top: 70px;
}

.projectleft{
    text-align: left;
    margin-left: 70px;
   width: 35%;
}

.projectright{
    text-align: left;
    width: 35%;
    margin-left: 70px;
    
   
    
}
.dotcontainer{
    text-align: left;
    
}

.projectdot{
    
height: 30px;
width: 30px;

border-radius: 50%;
display: inline;
position: absolute;
transform: translate(-70px);

}

.logoimage{
    position: absolute;
    width:100px;
    height: auto;
    scale: 0.6  0.6;
    transform: translate(-80px, -10px);
    z-index: -1;
    border: none;
    background-color: white;
    filter: drop-shadow(30px 10px 4px #5a9724);
    padding: 2px;
}

@media (max-width: 790px){
    .projectright{
        margin-right: 100px;
    }

    .projectscontainer{
        width:120%;
        margin-left: 25vw;
        margin-right: auto;
    }

   
}
.contact{
    width: 100%;
    height: fit-content;
    
   
}

.buttoncontainer{
    height: fit-content;
   
}



.buttonimage{
    position: absolute;
    width:400px;
    height: 200px;
    scale: 1  2.7;
    transform: translate(-250px, -35px);
    z-index: -1;
    
}

.button{
    width:200px;
    height: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
    background-color: rgba(0,0,0,0);
    
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 1200;
    font-family: 'Gowun Dodum';
   
    font-weight: 700;
    
    position: relative;
  
    margin-left:auto;
    margin-right: auto;
    
    font-weight: 700;
    transition-duration: 0.4s;
    
}

.button:hover {
    filter: drop-shadow(30px 10px 4px #c5c5c5);
    scale: 1.1;
    opacity: 1;
}

.button:active {
    
    transform: translateY(10px);
    scale: 0.9;
    filter: drop-shadow(10px 5px 1px #c5c5c5);
}

@media (max-width: 750px){
    .buttoncontainer{
        scale:0.7;
        
    }

    .button{
        margin-bottom: 50px;
        margin-top: 50px;
    }
}
.toptitle{
   
    
    margin-top: 200px;
    align-items: center;
    align-self: center;
    position: relative;
    width: 100%;
    width: 100%;
    
   margin-bottom: 200px;
    text-align: center;
}

.toptitlevalue{
    clear: both;
    font-family: 'Gowun Dodum';
    font-size: min(max(30px,3vw), 70px);
    font-weight: bolder;
    color: #6BB22B;
    text-align: center;
}

@media screen and (max-width: 950px) {
    .toptitle{
       margin-top: 50px;
        margin-bottom: 50px;
    }
  }
.webdevice{
    width: 100%;
   
    height: fit-content;
    background-color: white;
    padding-top: 50px;
}

.devicecontainer{
    width:70%;
    
    position: relative;
    height : 70vw;
    margin-left: auto;
    margin-right: auto;
  
  background-repeat: no-repeat;
  background-position: center center;
   
    background-size: 100%;
    -webkit-box-reflect: below -12vw linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
}

.screen{
    width: 92%;
    transform: translate(0, 8vw);
    height: 40vw;
   
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.screenimages{
  
display: flex;
transition: 0.5s;

}

.imageinside{
    min-width: 100%;
   
}

.navbtnR {
   
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
   position: absolute;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    right: 10px;
    top:50%;
 
  }

  .navbtnL {
   
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
   position: absolute;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    left: -50px;
    top:50%;
   
  }

  .arrowl{
    scale: -1 1;
    width: 12vw;
  }

  .arrowl:hover{
    filter: drop-shadow(10px 10px 4px #b1b1b1);
    transition: 0.5s;
  }

  .arrowr{
    width: 12vw;
   
  }

  .arrowright:h{
    filter: drop-shadow(10px 10px 4px #b1b1b1);
    transition: 0.5s;
  }

  .arrowr:active{
    scale: 2;
  }
  .arrowl:active{
    scale: -2 2;
  }


  .link{
    font-family: 'Gowun Dodum';
    font-size: 20px;
    color: #6BB22B;
   
  }


  .desbox{
    width:70%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.8);
 margin-top: 50px;
 margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }



  @media (min-width: 1500px){
    .devicecontainer{
      width:30%;
      height: 40vw;
      
      -webkit-box-reflect: below -15vw linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
  
    }
    
    .arrowr{
      width: 8vw;
     
    }

    .arrowl{
      width: 8vw;
     
    }
    
  }
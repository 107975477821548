.videodiv{
    width:100%;
    height:fit-content;
    min-height: 800px;
   background-color: white;
 
    
    
    
}

.videocontainer{
    display: inline-block;
    position: relative;
   
    margin-top: 0px;
    
   
   width:70%;
   margin-left: auto;
   margin-right: auto;
}

.player{
  z-index: 1;
padding-left: 45vw;
 position: absolute;

}


.video{
   min-width: 300px;
   
}

.showreeldescriptionright{
    
    font-family: 'Gowun Dodum';
  font-size: 17px;
  font-weight: 100;
  
  width: 50%;
}

.videopaintmark{
  position: absolute;
  
  right: 200px;
  top:100px;
  
}



@media (max-width: 800px){
    .player{
        padding-left: 0px;
      margin-left: auto;
       margin-right: auto;
      
       left: 50%;
       transform: translate(-50%, 0%); 
        }
        
        .showreeldescriptionright{
    
      padding-top: 400px;
      margin-bottom: 0px;
      position:relative;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      padding-left: 0px;
         width: 100%;
       text-align: center;
      }
        
 }

.tutorial{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 500px;
    padding-bottom: 50px;
    position: relative;
    
}


.explainblock{
    text-align: left;
    width: 50%;
    margin-right: 50px;
    margin-left: auto;
}

.explain{
    font-family: 'Annie Use Your Telescope';
    font-size: 30px;
}

.arrows{
    text-align: right;
   scale: 0.5;
  position: relative;
    width: 50%;
    max-width: 900px;
    height: 5vw;
    margin-left: 20px;
    margin-right: auto;
    
}

.click{
    width:20vw;
   left:20vw;
   top:10px;
   right: auto;
   position: absolute;
  
}

.up{
    position: absolute;
   top: 0px;
   left:0px;
    scale: 1; 
}

.down{
    position: absolute;
    top: 0px;
    left:0px;
    scale: 1 ; 
}

.left{
    position: absolute;
    top: 0px;
    left:0px;
    scale: 1 ; 
}

.right{
    position: absolute;
    top: 0px;
    left:0px;
    scale: 1 ; 
}
body {
    font-family: 'Gowun Dodum';
    text-align: center;
    align-items: center;
    align-self: center;
    position: relative;
    
}

html {
    width:100%;
}


.mynameis{
    clear: both;
    font-family: 'Gowun Dodum';
    font-size: 20px;
    font-weight: 100;
    color: #6BB22B;
    background-color: rgba(238, 238, 238, 0.1);
  
    backdrop-filter: blur(5px);
    
}

.role{
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 0px;
    margin-bottom: -20px;
    margin-left: auto;
    margin-right: auto;
    color: #6BB22B;
    width: 90%;
    background-color: rgba(238, 238, 238, 0.1);
  
    backdrop-filter: blur(5px);
    
}

.for{
    font-size: 30px;
    font-weight: 700;
    padding-top: 0px;
    color: #8bcc52;
    background-color: rgba(238, 238, 238, 0.1);
  
    backdrop-filter: blur(5px);
}

.mepic{
    
    position: relative;
    height: 200px;
   z-index: -2;
}

.jayimage{
    position: absolute;
    bottom: -415px;
    transform: translate(-350px,0px);
}

#bass{
    z-index: -1;
}

.playbass{
    font-family: 'Annie Use Your Telescope';
    font-size: 15px;
   
   transform: translate(200px,100px);
   top: 30px;
}

.arrow{
    position: absolute;
    scale: 0.5;
    bottom: 50px;
    
    transform:  translate(-0px, 50px) rotate(-40deg);
    z-index: -2;
}

.paintmark{
    position: absolute;
    transform: translate(-180px,-80px);
    top: -1vw;
}
.weblogos{
    display: flex;
    flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  margin-top: 50px;
  
  
}

.weblogos img {
    width:100px;
    height: 100px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
}

.builtblock{
 text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

}

.builttext{
align-self: left;

font-family: 'Annie Use Your Telescope';
font-size: 25px;
}

.built{
  height: fit-content;
}

.weblogos img:hover{
  scale:2;
  transition:0.5s
}


@media(max-width: 670px){
  .weblogos img {
    width:50px;
    height: 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
}
}
html {

    
    background-color: rgb(238, 238, 238);
    overflow-x: hidden;
   
}


#root{
    overflow: hidden;
    width:100%;
   
}

body{
    width: 100%;
    overflow-x: hidden;
}
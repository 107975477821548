.contactdetails{
   width: 100%;
    height: 200px;
    margin-bottom: 50px;
}

.contactcontainer{
    width: 90%;
    height: 100px;
   
    margin-left: auto;
    margin-right: auto;
}

.phonenumber{
    font-family: 'Gowun Dodum';
    font-size: min(max(16px, 1vw), 30px);
    margin: 0px;
}

.email{
    
        font-family: 'Gowun Dodum';
        font-size: min(max(16px, 1vw), 30px);
        margin: 0px;
}

.contactmessage{
    font-family: 'Annie Use Your Telescope';
    font-size: min(max(16px, 1vw), 30px);
}

#leftpaint{
    position: absolute;
    left:-150px;
    transform: translate(0,300px);
    
}

#rightpaint{
    position: absolute;
    right:-150px;
    transform: translate(0,400px);
    
}


@media (max-width: 950px){
    #leftpaint{
        
        transform: translate(0,300px);
        scale:0.6
    }
    #rightpaint{
      
        transform: translate(0,150px);
        scale:0.6
    }
}
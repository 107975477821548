.navbar a {
    float: left;
   
    padding-top: 20px;
    padding-bottom: 50px;
    color: #000000;
    text-decoration: none;
    font-size: 12px;
    width: 14%; 
    text-align: center; 
    margin: auto;
 
        font-family: "League Spartan", sans-serif;
        font-optical-sizing: auto;
        font-weight: 100;
        font-style: normal;
      
  }

  .logo{
    
    margin-top: -40px;
    margin-left: 0px;
  }
  
  
  /* Add a background color on mouse-over */
  .navbar a:hover {
    color: #6BB22B;
    font-weight: 700;
  }

  .navbar img:hover {
    scale: 2;
    transition: 0.5s;
  }
  
  /* Style the current/active link */
  .navbar a.active {
    color: #EAC082;
  }


  @media (max-width:500px){
    .navbar a {
      font-size:10px;
    }
    .logo{
      scale:0.7;
     
      margin-left: -17px;
    }
  }
.formdetails {
height: fit-content;
}


textarea {
    width: 50%;
    height: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    padding-top: 20px;
    margin-top: 20px;
  }

  .formcontainer{
    width: 100%;
    height: 600px;
    background-color: #6BB22B;
   position: relative;
   margin-bottom: 100px;
    
  }

  .form-group{
    padding-top: 50px;

  }
  
  input{
    width: 50%;
    height: 50px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
  }

  label{
    font-family: 'Gowun Dodum';
  font-size: 20px;
  font-weight: 100;
  color: white;
 position: relative;
  }

  button{
    width: 200px;
    height: 50px;
    font-family: 'Gowun Dodum';
  font-size: 20px;
  font-weight: 700;
  color: #6BB22B;
  margin-top: 30px;
  background-color: white;
  border: none;

  }
  
  .formbrushbottom{
    
 position: absolute;
 bottom: -140px;


    scale: 3 .5;
}

.formbrushtop{
    
  position: absolute;
  top: -110px;
 
 
     scale: 3 .5; 
}


@media (max-width: 2000px){
  .formbrushbottom{
   bottom: -90px;
  }
  .formbrushtop{
    top: -90px;}
}

@media (max-width: 1500px){
  .formbrushbottom{
   bottom: -50px;
  }
  .formbrushtop{
    top: -45px;}

}

@media (max-width: 751px){
  .formbrushbottom{
   bottom: -30px;
  }
  .formbrushtop{
    top: -25px;}

  button{
    width: 80%;
    font-size: 15px;
    }

    label{
    font-size: 15px;
    }

    input{
      width: 80%;
    }

    textarea {
      width: 80%;}

      .formdetails {
        margin-top: -30px;
        }
}


@media (max-width: 450px){
  .formbrushbottom{
   bottom: -17px;
  }
  .formbrushtop{
    top: -15px;}
}
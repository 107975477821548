.help{
    height:fit-content;
    width:100%;
 
    background-color: white;
    text-align: left;
    font-family: 'Gowun Dodum';
  font-size: 17px;
  font-weight: 100;
}

.helpcontainer{
    width:90%;
   
    margin-left: auto;
    margin-right: auto;
    
    
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.info{
   margin-top: 20px;
    width: 40%;
    text-align: center;
   margin-right: 10px;
   height: fit-content;
}

.subjects{
   
    width: 50%;
height: fit-content;
margin-bottom: 20px;
}






.subjectblock{
    width:100%;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    text-align: left;
    margin-left: 40px;
    padding-bottom: 0px;
    padding-top:20px;
    margin-bottom: 20px;
    position: relative;
    
}

.subjectblock:hover{
    filter: drop-shadow(10px 10px 4px #868686);
    scale:1.02;
    transition: 0.4s;
    width: 100%;
    height: fit-content;
}


.subjecticon {
    width:90px;
    align-self: left;
    display: inline;
    margin-left: 20px;
}

.subjecttitle{
    display: inline;
    margin-left: 40px;
    text-align: left;
    position: absolute;
}

.subexplain{
    display: inline-flex;
    margin-left: 40px;
   text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
    width:80% !important;
    
    font-family: 'Gowun Dodum' !important;
    font-size: min(max(16px, 1vw), 22px);
    height: fit-content !important;
}


.problemsolver{
    font-size: 20px;
}

.number{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: -10px;
    color: #6BB22B;
}

.of{
    font-size: 30px;
    margin-top: 0px;
    
}

.helpinfo{
    padding-top: 30px;
    padding-bottom: 30px;
}


@media (max-width:1500px){
 
    .subjects{
   
        width: 50%;
       
      
    }
  
    .subjectblock{
        width:50%;
     
      
    }

    .explain{
        width:38%;
    }
}





@media (max-width: 900px){
    .helpcontainer{
 
        display: flex;
        flex-direction: column;
        justify-content: center;
      
    }
    .subjects{
   
        width: 90%;
       
        margin-left: auto;
        margin-right: auto;
    }
    .info{
   
        width: 90%;
        text-align: center;
       margin-right: auto;
       margin-left: auto;
    }
    .subjectblock{
        width:80%;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 150px;
      
    }

    .explain{
        width:50%
    }
}
.balldiv{
   position: fixed; 
   top: 0px;
   left: 70vw;
  width: 100%;
  height: 100%;
  pointer-events: none;
  
}


.mycanvas{
   
    height: 500px;
    width:500px;
    position: absolute;
    margin: auto;
    
   
   transform: translate(0px, -220px);


}

.ball{
    background-color: rgb(18, 133, 34);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    top: 100px;
    left: 220px;
    animation: bounce 0.9s infinite;
    z-index: 2;
}

@keyframes bounce {
   10%{
    height: 50px;
    width:50px;
   } 
   30%{
    height: 50px;
    width:40px;
   } 
   50%{
    height: 30px;
    width:57px;
    transform: translateY(240px);
   }
   75%{
    height: 50px;
    width:57px;
   } 
   100%{
   
    transform: translateY(0px);
   }
}

.container{
    width: 57px;
    height:50px;
}

.shadow{
    background-color: rgba(34, 34, 34, 0.4);
    height: 10px;
    width: 65px;
    border-radius: 50%;
    position: relative;
    top:310px;
    left: 212px;
    z-index: 1;
    animation: shrink 0.9s infinite;
}

@keyframes shrink{
    50%{
        transform: scaleX(0.5);
    }
}
.characterdiv{
   
    height: 200px;
    width: 98%;
    height: 100%;
    position:fixed;
    top: 500px;
  
    border: none;
    pointer-events: none;
   overflow: hidden;
  
}


.character {
    transform: scaleX(-1);
    border:none;
    overflow: hidden;
   height: 147px;
   width:115px;
   position:fixed;
   transition:  1s;
   
}

.mecharacter{
   height: 150px;
   width: 155px;
   margin-top: -5px;
   margin-left: -20px;
  
 
}


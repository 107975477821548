.description{
    width:100%;
    height:fit-content;
    
    margin-top: 50px;
    margin-bottom: 100px;
}

.certification{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.descriptiontext{
    text-align: right;
    margin-right: 20%;
    width: 30%;
    margin-top: 0px;
}

.descriptionlogo{
   text-align: left;
    width: 30%;
    margin-left: 20%;
    margin-top: 25px;
}

.certlogo{
   
    width:130px;
   
    
}


.certtitle{
    
    text-align: right;
   
}


.certdescription{
   
    text-align: right;
   
}

@media (max-width: 650px){
    .certlogo{
        width:70px;
    }
    .certtitle{
        font-size: 15px;
    }
}
.videodivlft{
    width:100%;
    height:fit-content;
    min-height: 800px;
   background-color: white;
 
    
    
    
}

.videocontainerlft{
    display: inline-block;
    position: relative;
    height:fit-content;
    margin-top: 0px;
    
   
   width:80%;
   margin-left: auto;
   margin-right: auto;
}

.playerlft{
  z-index: 1;
padding-left: 5vw;
 position: absolute;

}


.videolft{
   min-width: 300px;
   
}

.showreeldescriptionlft{
    
    font-family: 'Gowun Dodum';
  font-size: 17px;
  font-weight: 100;
  margin-bottom: 100px;
  width: 40%;
  margin-left: auto;
   margin-right: 10px;
}

.videopaintmarklft{
  position: absolute;
  
  right: 4vw;
  top:100px;
  
}



@media (max-width: 800px){
    .playerlft{

      margin-left: auto;
       margin-right: auto;
      
       left: 50%;
       transform: translate(-50%, 0%); 
        }
        
        .showreeldescriptionlft{
    
      padding-top: 400px;
      position:relative;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      padding-left: 0px;
         width: 100%;
       text-align: center;
      }
        
 }
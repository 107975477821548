.showreelbutton{
    z-index: 1;
    margin-top: 50px;
    width: 300px;
    height: 50px;
    background-color: #6BB22B;
    border: white;
    color: white;
    margin-bottom: 100px;
    
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
   
    font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
   
    
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 460px;
    
    cursor: pointer;
    transform: rotate(-2deg);
  
 
    
}



.screen{
    height: fit-content;
padding-bottom: 100px;
 position: relative;
 margin-top: -150px;
 
}


.screenclass{
  width: 50%;
  height: 30vw !important;
 margin-top: -50px;
}





.showreelbutton:hover {
    filter: drop-shadow(10px 10px 10px #727272);
    transition: 0.4s;
    scale: 1.1
}

.showreelbutton:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
    transition: 0.4s;
  }





 

/* CSS */


.showreelbutton:focus {
  outline: 0;
}

.showreelbutton:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.showreelbutton:hover:after {
  bottom: 2px;
  left: 2px;
}


@media (max-width: 600px){
  .screen{
    height: fit-content;
padding-bottom: 200px;
 position: relative;
 margin-top: 0px;
 
}


.screenclass{
  width: 50%;
  height: 38vw !important;
 margin-top: 0px;
}

.showreelbutton{
  z-index: 5;
  margin-top: 50px;
  width: 200px;
  height: 50px;}
}
.graphicimages{
    
    width: auto;
    height: fit-content;
    padding-bottom: 50px;
    background-color: white;
}
      
.imagescontainer{
    
    width: 80%;
    height: fit-content;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    margin-bottom: 250px;
    left: 20px;
    right: auto;
  
    
    
}


    .imagescontainer > img:hover{
        scale: 1.1;
        transition: 0.4s;
    }


.square{
    width:20%; 
    min-width: 200px;
    display: inline-flex;
    transform: translate(-30px,70px);
  
    filter: drop-shadow(30px 10px 4px #a8a8a8);
}

.tall{
    width:20%;
    min-width: 200px;
    display: inline-flex;
    transform: translate(0px,30px);
    filter: drop-shadow(30px 10px 4px #a8a8a8);
}

.wide{
    width:20%;
    min-width: 200px;
    display: inline-flex;
    transform: translate(0px,60px);
    filter: drop-shadow(30px 10px 4px #a8a8a8);
}

#paintleft {
    transform: translate(300px,200px);
    z-index: -1;
  }

#paintright {
    transform: translate(-500px,0px);
    z-index: -1;
    
  }

  .graphicdescription{
    margin-top: 100px;
    font-family: 'Gowun Dodum';
  font-size: min(max(16px, 2vw), 22px);
  font-weight: 100;
  color: rgb(24, 24, 24);
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  }


  @media (max-width: 750px){
    .imagescontainer{
    
        margin-top: 0px;
        margin-bottom: 0px;

      
        
        
    }
  }


  @media (max-width: 500px){
    .square{
       
        transform: translate(-30px,0px);
 
    }
    
    .tall{

        transform: translate(20px,0px);

    }
    
    .wide{

        transform: translate(-20px,0px);

    }
  }

.title{
    position: relative;
    background-color: white;
    width: 100%;
  
    height: fit-content;
   padding-top: 20px;
   font-size: 20px;
    text-align: right;
    
    
}

.titleleft{
    text-align: left;
    padding-top: 20px;
}


.titlenameleft{
    margin-left: 100px;
    
}

.titlename{
   
   margin-right: 10vw;
   
    
}

.underline{
    
    scale: -1 0.4;
    transform: translate(0, -160px);
}


@media (max-width: 650px){
   .title{
    font-size:10px;
   }

   .titlenameleft{
    font-size:20px;
   }

   .underline{
    max-width:200px;
   
}
}
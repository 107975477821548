body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    align-items: center;
    align-self: center;
  
  
}

.block{
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    height: fit-content;
   
    margin-bottom: 100px;
}

.textblock{
   
    margin-top: 100px;
   text-align: center;
   position: absolute; 
   display: flex;
   justify-content: center;
   height: fit-content; /* You must set a specified height */
  width: 100vw;

background-size: 110% 40%;
}

.blockimage{
  height: 100%;
  width: 100vw;
position: absolute;

}

.blockimageinside{
height: 100%;
width: 100%;
scale: 30 1;

}

.about{
    z-index: 1;
    line-height: 1.5;
 
  vertical-align: middle;
  padding: 20px 0;
  width: 70%;
  font-family: 'Gowun Dodum';
  font-size: min(max(25px, 2vw), 17px);
  font-weight: 100;
  color: white
}

.brushbottom{
    display: flex;
    z-index: 2;
    position: absolute;
    transform: translate(0, 40px);
    scale: 1 0.5;
}

.brushtop{
    
    position: absolute;
    z-index: 1;
    
    scale: 1 0.5; 
}

.whiteblock{
    height: 20vw;
    width: 100%;
    background-color: white;
    z-index: -1;
    transform: translate(0, 250px);
    position: absolute;
    
}

.about{
    z-index: 5;
}

@media (max-width: 1600px){
    .blockimageinside{

        scale: 15 1;
        
        }
}

@media (max-width: 600px){

    .block{
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 100px;
        height: fit-content;
       
        margin-bottom: 250px;
    }
    
    .textblock{
       
        margin-top: 100px;
       text-align: center;
       position: absolute; 
       display: flex;
       justify-content: center;
       height: fit-content; /* You must set a specified height */
      width: 100vw;
    
    
    background-size: 110% 110%;
    }
    
    .blockimage{
      height: 100%;
      width: 100%;
    position: absolute;
    
    }
    
    .blockimageinside{
    height: 100%;
    width: 100%;
    scale: 30 1.5;
    
    }

}